import React from 'react';

import {MDXProvider} from '@mdx-js/react';

import {CSSObject} from '@emotion/styled';

import {SymbolDoc} from '@workday/canvas-kit-docs';
import {colors, type} from '@workday/canvas-kit-react/tokens';

import StyledElements from './StyledElements';
import {InternalContent, ExternalContent} from './LimitedContent';
import {Do, Dont} from './content/DosAndDonts';
import {NoticeCard} from './content/NoticeCard';
import CodeBlock from './content/code/CodeBlock';
import ExampleCodeBlock from './content/code/ExampleCodeBlock';
import PropsTable from './content/code/PropsTable';
import {MdxFile} from './content/code/MdxFile';
import {Tabs} from '@workday/canvas-kit-react/tabs';
import {AnimatedIllustration} from './content/AnimatedIllustration';
import SearchableIconList from './content/icons/SearchableIconList';
import EmptyStateList from './content/EmptyStateList';
import {Image, FormattedImage} from './content/Image';
import {Gif} from './content/Gif';
import Keyline from './content/Keyline';
import {Link, LegacyPatternLink} from './content/Link';
import {PackageInfo} from './content/PackageInfo';
import {WistiaVideo} from './content/WistiaVideo';
import {Breadcrumbs} from './nav/Breadcrumbs';
import {Suggestion} from './content/Suggestion';
import {SideBySide} from './content/SideBySide';
import {CustomTable} from './content/Table';
import {BestPractices} from './content/BestPractices';
import {
  ImplementationDetails,
  ProductExampleCards,
  AllProductExampleCards,
} from './content/PatternDetails';

import {
  DefaultTemplate,
  ComponentTemplate,
  TabsTemplate,
  DocumentedPatternTemplate,
  TemplateFrontmatter,
} from './templates';

export interface MDXLayoutProps {
  children: React.ReactElement;
  pageContext?: {
    frontmatter: TemplateFrontmatter;
  };
}

const propertiesTableStyles: CSSObject = {
  table: {
    td: {
      ...type.levels.subtext.medium,
      '&:nth-of-type(1) code': {
        background: colors.blueberry100,
        color: colors.blueberry400,
        fontWeight: type.properties.fontWeights.bold,
      },
    },
    code: {
      fontSize: type.properties.fontSizes[12],
      whiteSpace: 'normal',
    },
  },
};

// See https://www.gatsbyjs.org/docs/mdx/importing-and-using-components/#make-components-available-globally-as-shortcodes
const shortcodes = {
  ...StyledElements,
  a: Link,
  img: Image,
  FormattedImage,
  InternalContent,
  ExternalContent,
  pre: props => <div {...props} />, // eslint-disable-line react/display-name
  code: CodeBlock,
  CodeBlock,
  ExampleCodeBlock,
  MdxFile,

  // This was the best way I could find to make Tabs sub-components available
  // in the MDX without getting "ReferenceError: Tabs is not defined." FWIW,
  // importing Tabs in the MDX file itself allows us to use <Tabs.Panel> in
  // the MDX file, but we don't want to import Tabs in every single MDX file.
  TabPanel: props => <Tabs.Panel {...props} />,

  // This works, but it requires us to use <Panel> in the MDX.
  //...Tabs,

  // None of these work (all throw the same error about Tabs not being defined)
  // Tabs,
  // 'Tabs.Panel': Tabs.Panel,
  // 'Tabs.Panel': props => <Tabs.Panel {...props} />,

  PropsTable,
  ArgsTable: PropsTable, // Also support Storybook nomenclature
  AnimatedIllustration,
  Breadcrumbs,
  SearchableIconList,
  EmptyStateList,
  Do,
  Dont,
  Keyline,
  NoticeCard,
  WistiaVideo,
  Suggestion,
  SideBySide,
  Gif,
  PackageInfo,
  CustomTable,
  LegacyPatternLink,
  ImplementationDetails,
  BestPractices,
  ProductExampleCards,
  AllProductExampleCards,
  // Override styles of PropertiesTable rendered by custom docgen
  //
  // TODO: Instead of overriding styles here, consider updating styles of
  // PropertiesTable in canvas-kit.
  SymbolDoc: props => <SymbolDoc css={propertiesTableStyles} {...props} />,
};

const getTemplateComponent = (template?: string) => {
  switch (template) {
    case 'component':
      return ComponentTemplate;
    case 'tabs':
      return TabsTemplate;
    case 'documentedPattern':
      return DocumentedPatternTemplate;
    default:
      return DefaultTemplate;
  }
};

const MDXLayout = ({children, pageContext}: MDXLayoutProps) => {
  if (!pageContext) {
    return <MDXProvider components={shortcodes}>{children}</MDXProvider>;
  }

  const frontmatter = pageContext.frontmatter;
  const Template = getTemplateComponent(frontmatter.template);

  return (
    <MDXProvider components={shortcodes}>
      <Template frontmatter={frontmatter}>{children}</Template>
    </MDXProvider>
  );
};

export default MDXLayout;
