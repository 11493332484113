import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "grids-tables"
    }}>{`Grids (Tables)`}</h1>
    <p>{`Grids or tables are UI containers to hold data and other information in a table-like format. Workday
intends to update our grid tooling in the near future to improve performance, meet accessibility
standards, and improve usability. Content usage for the new Tables 2.0 tool will follow the same
standards as for grids. For other usage and accessibility guidance, see
`}<a parentName="p" {...{
        "href": "/components/containers/table"
      }}>{`Tables`}</a>{`.`}</p>
    <p>{`Other considerations:`}</p>
    <ul>
      <li parentName="ul">{`Due to limited space in grids or tables, you can use abbreviations (including avg., hrs., or wk.),
symbols (% for percent), and other shortened forms that you would not use elsewhere in the UI.`}</li>
      <li parentName="ul">{`Use singular for column heading labels, such as Currency, Company, Organization, or Period instead
of the plural format.`}</li>
      <li parentName="ul">{`You should still follow the rules for appropriately styling
`}<a parentName="li" {...{
          "href": "/guidelines/content/numbers/dates-times"
        }}>{`dates, months, and times`}</a>{`.`}</li>
      <li parentName="ul">{`Use unique heading labels for each column. No need to repeat label prefixes or suffixes that would
be obvious to the user based on the task or grid name.`}</li>
    </ul>
    <Do mdxType="Do">
      <p>{`Hours Worked in May`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Worker Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Hrs.`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`01/09/20`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Karen Oh`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`6`}</td>
          </tr>
        </tbody>
      </table>
    </Do>
    <Dont mdxType="Dont">
      <p>{`Hours Worked in May`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Dates worked`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Names of workers`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Hours worked`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`1/9/20`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Karen Oh`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Six`}</td>
          </tr>
        </tbody>
      </table>
    </Dont>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      